<template>
  <base-section id="how-security">
    <base-section-heading
      color="primary lighten-2"
      :title="$i18n.t('title')"
      :text="$i18n.t('subtitle')"
    />

    <v-container>
      <v-row>
        <v-col
          v-for="(card, i) in fullCards"
          :key="i"
          cols="12"
          md="4"
        >
          <base-info-card v-bind="card" />
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAboutOurProduct',

    data: () => ({
      cards: [
        {
          icon: 'fas fa-spray-can',
          title: ' ',
          callout: '1',
        },
        {
          icon: 'fas fa-qrcode',
          title: ' ',
          callout: '2',
        },
        {
          icon: 'fas fa-umbrella-beach',
          title: ' ',
          callout: '3',
        },
      ],
    }),

    computed: {
      fullCards () {
        return this.cards.map((card, i) => {
          card.text = this.$i18n.t(`t${i}.text`)
          return card
        })
      },
    },
  }
</script>
<i18n>
{
	"en": {
		"subtitle": "To ensure the transparency of the interventions we decided to rely on Minnovi, which deals with the recording of sanitization interventions, a software house that already successfully uses systems for tracking interventions and processes.",
		"t0": {
			"text": "The operator performs the sanitization of sunbeds and umbrella with a low environmental impact disinfectant product"
		},
		"t1": {
			"text": "At the end of the disinfection operation, the operator scans the QR code on the umbrella, through the appropriate Sanybeach app designed for sanitation workers"
		},
		"t2": {
			"text": "You can check the sanitisation with time and details by scanning the same QR code that you find on the umbrella"
		},
		"title": "Transparency of the sanification \n = \n Serenity"
	},
	"it": {
		"subtitle": "Per garantire la trasparenza degli interventi abbiamo deciso di affidarci a Minnovi, che si occupa della registrazione degli interventi di sanificazione, software house che già utilizza con successo sistemi per la tracciatura degli interventi e delle lavorazioni",
		"t0": {
			"text": "L’operatore esegue la sanificazione di lettini e ombrellone con prodotto disinfettante a basso impatto ambientale"
		},
		"t1": {
			"text": "Al termine dell’intervento di disinfezione, l’operatore scannerizza il codice QR sull’ombrellone, tramite l’apposita app di Sanybeach pensata per gli addetti alla sanificazione"
		},
		"t2": {
			"text": "Tu puoi verificare l'avvenuta sanificazione con orario e dettagli scannerizzando lo stesso codice QR che trovi sull'ombrellone"
		},
		"title": "Trasparenza della sanificazione \n = \n serenità"
	}
}
</i18n>
